<template>
  <transition name="fade">
    <div
      v-if="isVisible"
      class="modal fixed left-0 top-0 size-full overflow-auto"
    >
      <div
        v-click-outside="close"
        class="modal-content relative m-auto"
        :class="[
          {
            'modal-content-mobile-fullscreen': fullscreenOnMobile,
          },
          fullScreenModal ? 'md:max-w-[800px]' : 'md:max-w-[480px]',
        ]"
      >
        <div
          class="rounded-xl bg-background-extra-light"
          :class="{
            'modal-inner-content-mobile-fullscreen': fullscreenOnMobile,
          }"
        >
          <Button
            v-if="showCloseButton"
            class="absolute right-4 top-4 z-10 m-1 py-3"
            size="sm"
            color="secondary"
            @click="close"
          >
            <DynamicPictogram name="times" />
          </Button>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { toggleBodyScroll } from '@/helpers/utils';

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  showCloseButton: {
    type: Boolean,
    default: true,
  },
  fullscreenOnMobile: {
    type: Boolean,
    default: false,
  },
  fullScreenModal: {
    type: Boolean,
    default: false,
  },
});

const { isVisible } = toRefs(props);

const emit = defineEmits(['close']);

const close = () => {
  emit('close', false);
};

watch(
  isVisible,
  (visible) => {
    if (process.client) {
      toggleBodyScroll(!visible);
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  if (process.client) {
    toggleBodyScroll(true);
  }
});
</script>

<style lang="postcss" scoped>
/* The Modal (background) */
.modal {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 999;
}

/* Modal Content/Box */
.modal-content {
  width: 90%;
  top: 50%;
  transform: translateY(-50%);
  max-height: 90%;
}

@media (max-width: theme('screens.md')) {
  .modal-content-mobile-fullscreen {
    @apply w-full h-full max-h-full;
  }
  .modal-inner-content-mobile-fullscreen {
    @apply rounded-none flex items-center min-h-full;
  }
}
</style>
